
import { Vue, Component } from 'vue-property-decorator'
import { apiRecordConfig, apiRecordConfigEdit } from '@/api/setting'

@Component
export default class SettingRecord extends Vue {
    /** S Data **/
    // 表单数据
    form: any = {
      copyright: '', // 版权信息
      record_no: '', // 备案号
      record_url: '' // 备案号链接
    };

    // 表单验证
    rules: object = {
      record_url: [
        {
          pattern:
                    /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/,
          message: '请输入合法链接',
          trigger: 'blur'
        }
      ]
    };
    /** E Data **/

    /** S Methods **/
    // 初始化表单数据
    async initFormData (): Promise<void> {
      const res: any = await apiRecordConfig()
      for (const key in res) {
        this.form[key] = res[key]
      }
    }

    // 重置表单数据
    onResetFrom () {
      this.initFormData()
      this.$message.info('已重置数据')
    }

    // 提交表单
    onSubmitFrom (formName: string) {
      const refs = this.$refs[formName] as HTMLFormElement

      refs.validate(async (valid: boolean): Promise<void> => {
        if (!valid) return
        const loading = this.$loading({ text: '保存中' })

        await apiRecordConfigEdit({
          ...this.form
        }).finally(() => {
          loading.close()
        })
        this.$store.dispatch('getConfig')
      })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created () {
      this.initFormData()
    }
    /** E Life Cycle **/
}
